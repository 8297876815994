import { setAuthTokens, clearAuthTokens } from 'axios-jwt'
import axios from 'axios';
import jwt_decode from "jwt-decode";

function getLocalAccessToken() {
    const accessToken = window.localStorage.getItem("accessToken");
    return accessToken;
}

function getLocalRefreshToken() {
    const refreshToken = window.localStorage.getItem("refreshToken");
    return refreshToken;
}

const BASE_URL = process.env.REACT_APP_API_URL;

// 1. Create an axios axiosInstance that you wish to apply the interceptor to
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

// Interceptor AXIOS qui permet de set le token en header à chaque requetes
axiosInstance.interceptors.request.use(
    (config) => {
        const token = "Bearer " + getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor AXIOS qui permet de gérer les 401 , token expiré & refresh token
axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const rs = await refreshToken();
                    const jwtToken = rs.data.token;
                    window.localStorage.setItem("accessToken", jwtToken);

                    const token = "Bearer " + jwtToken;
                    if (token) {
                        axiosInstance.defaults.headers.common["Authorization"] = token;
                    };

                    return axiosInstance(originalConfig);
                } catch (_error) {

                    if (_error.response.status === 401 && originalConfig._retry) {

                        // Logout
                        logoutRequest();
                        window.location.href = '/auth/sign-in';
                        return false;
                        //return Promise.reject(_error.response.data);
                    }

                    else if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }

                    return Promise.reject(_error);
                }
            }

            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }

        return Promise.reject(err);
    }
);

function refreshToken() {
    return axios.post(BASE_URL + "auth/refresh_token", {
        refresh_token: getLocalRefreshToken(),
    });
}

// 4. Logging in
const loginRequest = async (params) => {

    const response = await axios.post(BASE_URL + 'auth/login', params)

    const accessToken = response.data.token;
    const refreshToken = response.data.refresh_token;
    const decoded = jwt_decode(accessToken);

    window.localStorage.setItem("accessToken", accessToken);
    window.localStorage.setItem("refreshToken", refreshToken);
    window.localStorage.setItem("loggedUser", decoded.username);

    // save tokens to storage
    setAuthTokens({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        username: decoded.username
    })
}

// 5. Logging out
const logoutRequest = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
    window.localStorage.removeItem("loggedUser");
    clearAuthTokens();
}

// Stuff

function getPreviousMonday() {
    var d = new Date();
    var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

function getNextSunday() {
    var dayIndex = 0;
    var d = new Date();
    d.setDate(d.getDate() + (dayIndex - 1 - d.getDay() + 7) % 7 + 1);
    return d;
}

// Requêtes entités du projet

// Récupération des mariages
const getWeddingsRequest = async (params) => {

    return axiosInstance.get('weddings')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}
// Ajouter un mariage
const addWeddingRequest = async (data) => {

    return axiosInstance.post('weddings', data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}


// Mise à jour d'un mariage
const updateWeddingRequest = async (id, data) => {

    return axiosInstance.put('weddings/' + id, data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Récupération d'un mariage via son id
const getWeddingRequest = async (weddingID) => {

    return axiosInstance.get('weddings/' + weddingID)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}

// Récupération des mariages de la semaine
const getWeddingsForThisWeek = async () => {

    var startDate = getPreviousMonday();
    var after = startDate.toISOString().substring(0,10) + 'T00:00:00';

    var endDate = getNextSunday();
    var before = endDate.toISOString().substring(0,10) + 'T23:59:59';

    return axiosInstance.get('weddings?wdate[after]=' + after + '&wdate[before]=' + before + ' &status=1')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Supprimer un mariage
const deleteWeddingRequest = async (weddingID) => {

    return axiosInstance.delete('weddings/' + weddingID)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}

//////////////////////////
// CONTACTS
//////////////////////////

// Récupération de tous les contacts
const getContactsRequest = async (weddingID) => {

    return axiosInstance.get('wedding_contacts?order[firstname]=asc&order[lastname]=asc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des personnes à contacter
const getContactsToCall = async () => {

    return axiosInstance.get('wedding_contacts?isCalled=false')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des contacts d'un mariage
const getWeddingContactsRequest = async (weddingID) => {

    return axiosInstance.get('wedding_contacts?wedding=' + weddingID + '&order[firstname]=asc&order[lastname]=asc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Ajouter un contact
const addContactRequest = async (data) => {

    return axiosInstance.post('wedding_contacts', data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Récupération d'un contact via son id
const getContactRequest = async (contactID) => {

    return axiosInstance.get('wedding_contacts/' + contactID)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}

// Mise à jour d'un contact
const updateContactRequest = async (id, data) => {

    return axiosInstance.put('wedding_contacts/' + id, data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

//////////////////////////
// RITUELS
//////////////////////////

// Récupération de tous les rituels
const getRitualsRequest = async () => {

    return axiosInstance.get('wedding_rituals')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        }
        )
}
// Récupération des rituals d'un mariage
const getWeddingRitualsRequest = async (weddingID) => {

    return axiosInstance.get('wedding_rituals?wedding=' + weddingID + '&order[time]=asc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Ajouter un rituel
const addRitualRequest = async (data) => {

    return axiosInstance.post('wedding_rituals', data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Récupération d'un rituel via son id
const getRitualRequest = async (ritualID) => {

    return axiosInstance.get('wedding_rituals/' + ritualID)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}

// Mise à jour d'un rituel
const updateRitualRequest = async (id, data) => {

    return axiosInstance.put('wedding_rituals/' + id, data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Suppression d'un rituel
const deleteRitualRequest = async (id) => {

    return axiosInstance.delete('wedding_rituals/' + id)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Récupération des types de rituels
const getRitualTypesRequest = async (params) => {

    return axiosInstance.get('ritual_types')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}


//////////////////////////
// PRESTATAIRES
//////////////////////////

// Récupération des providers d'un mariage
const getWeddingProvidersRequest = async (weddingID) => {

    return axiosInstance.get('wedding_providers?wedding=' + weddingID + '&order[title]=asc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Ajouter un prestataire
const addProviderRequest = async (data) => {

    return axiosInstance.post('wedding_providers', data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Récupération d'un prestataire via son id
const getProviderRequest = async (ritualID) => {

    return axiosInstance.get('wedding_providers/' + ritualID)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}

// Mise à jour d'un prestataire
const updateProviderRequest = async (id, data) => {

    return axiosInstance.put('wedding_providers/' + id, data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Suppression d'un prestataire
const deleteProviderRequest = async (id) => {

    return axiosInstance.delete('wedding_providers/' + id)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Récupération des types de prestataires
const getProviderTypesRequest = async (params) => {

    return axiosInstance.get('provider_types')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}


//////////////////////////
// RENDEZ-VOUS
//////////////////////////

// Récupération de tous les meetings
const getMeetingsRequest = async () => {

    return axiosInstance.get('wedding_meetings')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des échéances de la semaine
const getMeetingsForThisWeek = async () => {

    var startDate = getPreviousMonday();
    var after = startDate.toISOString().substring(0,10) + 'T00:00:00';

    var endDate = getNextSunday();
    var before = endDate.toISOString().substring(0,10) + 'T23:59:59';

    return axiosInstance.get('wedding_meetings?meeting_date[after]=' + after + '&meeting_date[before]=' + before + ' &status=2')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des meetings d'un mariage
const getWeddingMeetingsRequest = async (weddingID) => {

    return axiosInstance.get('wedding_meetings?wedding=' + weddingID + '&order[meetingDate]=asc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Ajouter un rendez-vous
const addMeetingRequest = async (data) => {

    return axiosInstance.post('wedding_meetings', data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Récupération d'un rendez-vous via son id
const getMeetingRequest = async (meetingID) => {

    return axiosInstance.get('wedding_meetings/' + meetingID)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}

// Mise à jour d'un rendez-vous
const updateMeetingRequest = async (id, data) => {

    return axiosInstance.put('wedding_meetings/' + id, data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Suppression d'un rendez-vous
const deleteMeetingRequest = async (id) => {

    return axiosInstance.delete('wedding_meetings/' + id)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}


//////////////////////////
// ECHEANCES - MILESTONES
//////////////////////////

// Récupération de toutes les échéances
const getMilestonesRequest = async () => {

    return axiosInstance.get('wedding_milestones')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des échéances de la semaine
const getMilestonesForThisWeek = async () => {

    var startDate = getPreviousMonday();
    var after = startDate.toISOString().substring(0,10) + 'T00:00:00';

    var endDate = getNextSunday();
    var before = endDate.toISOString().substring(0,10) + 'T23:59:59';

    return axiosInstance.get('wedding_milestones?due_date[after]=' + after + '&due_date[before]=' + before + ' &status=1')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des milestones d'un mariage
const getWeddingMilestonesRequest = async (weddingID) => {

    return axiosInstance.get('wedding_milestones?wedding=' + weddingID + '&order[milestoneDate]=asc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Ajouter une échéance
const addMilestoneRequest = async (data) => {

    return axiosInstance.post('wedding_milestones', data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}


// Récupération d'une échéance via son id
const getMilestoneRequest = async (milestoneID) => {

    return axiosInstance.get('wedding_milestones/' + milestoneID)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}

// Mise à jour d'une échéance
const updateMilestoneRequest = async (id, data) => {

    return axiosInstance.put('wedding_milestones/' + id, data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Suppression d'une échéance
const deleteMilestoneRequest = async (id) => {

    return axiosInstance.delete('wedding_milestones/' + id)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}


//////////////////////////
// PAIEMENTS
//////////////////////////

// Récupération de tous les paiements
const getPaymentsRequest = async () => {

    return axiosInstance.get('wedding_payments?order[dueDate]=asc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des paiements de la semaine
const getPaymentsForThisWeek = async () => {

    var startDate = getPreviousMonday();
    var after = startDate.toISOString().substring(0,10) + 'T00:00:00';

    var endDate = getNextSunday();
    var before = endDate.toISOString().substring(0,10) + 'T23:59:59';

    return axiosInstance.get('wedding_payments?dueDate[after]=' + after + '&dueDate[before]=' + before + ' &status=1')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Récupération des paiements d'un mariage
const getWeddingPaymentsRequest = async (weddingID) => {

    return axiosInstance.get('wedding_payments?wedding=' + weddingID + '&order[dueDate]=asc')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

// Ajouter un paiement
const addPaymentRequest = async (data) => {

    return axiosInstance.post('wedding_payments', data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Récupération d'un paiement via son id
const getPaymentRequest = async (ritualID) => {

    return axiosInstance.get('wedding_payments/' + ritualID)
        .then(function(response) {
            const data = response.data;
            return data;
        })
}

// Mise à jour d'un paiement
const updatePaymentRequest = async (id, data) => {

    return axiosInstance.put('wedding_payments/' + id, data)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Suppression d'un paiement
const deletePaymentRequest = async (id) => {

    return axiosInstance.delete('wedding_payments/' + id)
        .then(function(response) {
            const data = response.data;
            return data;
        })
        .catch(function (error) {
            return error;
        })
}

// Récupération des modes de paiements
const getPaymentMethodsRequest = async (params) => {

    return axiosInstance.get('payment_methods')
        .then(function(response) {
            const data = response.data['hydra:member'];
            return data;
        })
}

export { getPaymentMethodsRequest, deletePaymentRequest, updatePaymentRequest, getPaymentRequest, addPaymentRequest, getWeddingPaymentsRequest, getPaymentsForThisWeek, getPaymentsRequest ,getProviderTypesRequest, deleteProviderRequest, updateProviderRequest, getProviderRequest, addProviderRequest, getWeddingProvidersRequest, deleteMilestoneRequest, updateMilestoneRequest, getMilestonesForThisWeek, getMilestonesRequest, getMilestoneRequest, addMilestoneRequest, getWeddingMilestonesRequest, deleteMeetingRequest, updateMeetingRequest, getMeetingRequest, addMeetingRequest, getMeetingsForThisWeek, getMeetingsRequest, getWeddingMeetingsRequest, getRitualTypesRequest, deleteRitualRequest, updateRitualRequest, getRitualRequest, addRitualRequest, getRitualsRequest, getWeddingRitualsRequest, updateContactRequest, getContactRequest, addContactRequest, getContactsToCall, getContactsRequest, getWeddingContactsRequest, deleteWeddingRequest, getWeddingsForThisWeek, getWeddingRequest, updateWeddingRequest, addWeddingRequest, getWeddingsRequest, getLocalAccessToken, loginRequest, logoutRequest };
