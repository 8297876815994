import React, {useEffect, useState} from 'react';
import {Row, Col, Form, Button, Modal} from 'react-bootstrap';
import Card from '../../components/Card';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addStoreRitual} from "../../store/redux";
import {addRitualRequest, getWeddingRequest} from "../../requests/api";

const AddRitual = (props) => {

    const weddingID = props.match.params.weddingid;

    const [formRitualType, setFormRitualType] = useState(0);
    const [formParticipants, setFormParticipants] = useState("");
    const [formObservations, setFormObservations] = useState("");
    const [formStatus, setFormStatus] = useState(0);
    const [formTime, setFormTime] = useState('00:00');

    const dispatch = useDispatch();

    const [wedding, setWedding] = useState(null);
    const ritualTypes = useSelector((state) => state.ritualTypes.ritualTypes);

    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleClose = () => setShow(false);
    const handleCancel = () => setShow(true);

    let history =useHistory()

    useEffect(() => {

        const getWeddingInfos = async () => {

            await getWeddingRequest(weddingID)
                .then(function(response) {
                    const wedding = response;
                    setWedding({...wedding, id: wedding.id});
                });

        }

        getWeddingInfos();

    }, [weddingID])

    const cancelForm = () => {
        history.push('/weddings/dashboard/' + wedding.id);
    }

    const getWeddingTitle = (wedding) => {
        if(wedding) {
            return 'Mariage de ' + wedding.title + ' le ' + getWeddingFullDate(wedding);
        }

        return false;
    }

    const getWeddingFullDate = (wedding) => {
        if(wedding.wdate) {
            return new Date(wedding.wdate).toLocaleDateString();
        }
        return false;
    }

    const saveForm = async () => {

        const prepareObject = {
            type: formRitualType,
            participants: formParticipants,
            time: formTime,
            status: parseInt(formStatus),
            note: formObservations,
            wedding: 'weddings/' + wedding.id
        };

        addRitualRequest(prepareObject)
            .then((response) => {

                if (response.name === 'AxiosError') {

                    console.log('error');
                    console.log(response);

                    setShowError(true);
                    if(response.response.data['hydra:description']) {
                        setErrorMessage(response.response.data['hydra:description']);
                    }
                    else {
                        setErrorMessage(response.message);
                    }

                    return false;

                } else {

                    dispatch(addStoreRitual(response));
                    // SUCCESS
                    history.push('/weddings/dashboard/' + wedding.id);

                }
            });
    }

    return (
        <>
            <div>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <div className="card-title mb-0">
                                    <h4 className="mb-0">Nouveau rituel</h4>
                                    <h5 className="mb-0">{getWeddingTitle(wedding)}</h5>
                                </div>
                                <div className="card-action">
                                    <Button onClick={cancelForm} className="btn btn-secondary" role="button">Retour</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Informations</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="ritualType">Type de rituel</Form.Label>
                                                <select className="form-select" id="ritualType" onChange={(event) => {
                                                    setFormRitualType(event.target.value);
                                                }}
                                                >
                                                    <option key={""} value="0">Sélectionner un type de rituel</option>
                                                    {ritualTypes.map((ritueltype,ritueltypeindex) => (
                                                        <option key={"ritual" + ritueltypeindex} value={ritueltype['@id']}>{ritueltype.title}</option>
                                                    ))}
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="status">Statut</Form.Label>
                                                <select className="form-select" id="status" onChange={(event) => {
                                                    setFormStatus(event.target.value);
                                                }}
                                                >
                                                    <option>Séléctionner un statut</option>
                                                    <option value="1">A valider</option>
                                                    <option value="2">Confirmé</option>
                                                    <option value="3">Annulé</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="time">Heure</Form.Label>
                                                <Form.Control type="time"  id="time" onChange={(event) => {
                                                    setFormTime(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Informations additionnelles</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="participants">Participants</Form.Label>
                                                <Form.Control type="text" id="participants" onChange={(event) => {
                                                    setFormParticipants(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="observations">Notes/Observations</Form.Label>
                                                <Form.Control as="textarea" rows="5" id="observations" onChange={(event) => {
                                                    setFormObservations(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="button" variant="btn btn-danger" onClick={handleCancel}>Annuler</Button>{' '}
                        <Button type="button" variant="btn btn-primary" onClick={saveForm}>Enregistrer</Button>
                    </Col>
                </Row>
            </div>
            <Modal className="modalError" show={showError} onHide={() => setShowError(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className={"text-danger dots"}>
                        {errorMessage}
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowError(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ne pas enregistrer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Attention les informations de ce formulaire ne seront pas enregsitrées. Confirmer ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Revenir à l'édition
                    </Button>
                    <Button variant="primary" onClick={cancelForm}>
                        Je souhaite abandonner la saisie en cours
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddRitual
