import React, {useState} from 'react';
import {Row, Col, Form, Button, Modal} from 'react-bootstrap';
import Card from '../../components/Card';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {addStoreWedding} from "../../store/redux";
import {addWeddingRequest} from "../../requests/api";

const AddWedding = (props) => {

    const [formTitle, setFormTitle] = useState("");
    const [formWeddingDate, setFormWeddingDate] = useState("");
    const [formStatus, setFormStatus] = useState("");
    const [formObservations, setFormObservations] = useState("");
    const [formPlace, setFormPlace] = useState("");
    const [formCity, setFormCity] = useState("");
    const [formZipcode, setFormZipcode] = useState("");
    const [formPackage, setFormPackage] = useState("");
    const [formNbParticipants, setFormNbParticipants] = useState("");
    const [formServicePrice, setFormServicePrice] = useState("");
    const [formTravelExpense, setFormTravelExpense] = useState("");
    const [formTotal, setFormTotal] = useState("");
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleClose = () => setShow(false);
    const handleCancel = () => setShow(true);

    let history =useHistory()

    const cancelWeddingAdd = () => {

        history.push('/weddings');

    }

    const saveWedding = async () => {

        const weddingObject = {
            title: formTitle,
            wdate: formWeddingDate,
            note: formObservations,
            place: formPlace,
            city: formCity,
            zipcode: formZipcode,
            status: parseInt(formStatus),
            package: formPackage,
            nbGuests: parseInt(formNbParticipants),
            servicePrice: parseFloat(formServicePrice),
            travelExpense: parseFloat(formTravelExpense),
            total: parseFloat(formTotal)
        };

        addWeddingRequest(weddingObject)
            .then((response) => {

                if (response.name === 'AxiosError') {

                    console.log('error');
                    console.log(response);

                    setShowError(true);
                    if(response.response.data['hydra:description']) {
                        setErrorMessage(response.response.data['hydra:description']);
                    }
                    else {
                        setErrorMessage(response.message);
                    }

                    return false;

                } else {

                    dispatch(addStoreWedding(response));
                    // SUCCESS
                    history.push('/weddings/dashboard/' + response.id);

                }
        });
    }

    return (
        <>
            <div>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Nouveau mariage</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="title">Titre</Form.Label>
                                                <Form.Control type="text" id="title" onChange={(event) => {
                                                    setFormTitle(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="wdate">Date du mariage</Form.Label>
                                                <Form.Control type="date"  id="wdate" onChange={(event) => {
                                                    setFormWeddingDate(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="place">Lieu du mariage</Form.Label>
                                                <Form.Control type="text"  id="place" onChange={(event) => {
                                                    setFormPlace(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="city">Ville</Form.Label>
                                                <Form.Control type="text"  id="city" onChange={(event) => {
                                                    setFormCity(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="zipcode">Code postal</Form.Label>
                                                <Form.Control type="text"  id="zipcode" onChange={(event) => {
                                                    setFormZipcode(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Forfait</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="package">Nom du forfait</Form.Label>
                                                <Form.Control type="text"  id="package" onChange={(event) => {
                                                    setFormPackage(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="serviceprice">Prix de la prestation</Form.Label>
                                                <Form.Control type="text"  id="serviceprice" onChange={(event) => {
                                                    setFormServicePrice(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="travelexpense">Frais de déplacement</Form.Label>
                                                <Form.Control type="text"  id="travelexpense" onChange={(event) => {
                                                    setFormTravelExpense(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="total">Montant total</Form.Label>
                                                <Form.Control type="text"  id="total" onChange={(event) => {
                                                    setFormTotal(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Informations additionnelles</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="status">Statut</Form.Label>
                                                <select className="form-select" id="status" onChange={(event) => {
                                                    setFormStatus(event.target.value);
                                                }}
                                                >
                                                    <option>Séléctionner un statut</option>
                                                    <option value="1">En cours</option>
                                                    <option value="2">Annulé</option>
                                                    <option value="3">Terminé</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="nbparticipants">Nombre d'invités</Form.Label>
                                                <Form.Control type="text" id="nbparticipants" onChange={(event) => {
                                                    setFormNbParticipants(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="observations">Notes/Observations</Form.Label>
                                                <Form.Control as="textarea" rows="5" id="observations" onChange={(event) => {
                                                    setFormObservations(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Button type="button" variant="btn btn-danger" onClick={handleCancel}>Annuler</Button>{' '}
                        <Button type="button" variant="btn btn-primary" onClick={saveWedding}>Enregistrer</Button>
                    </Col>
                </Row>
            </div>
            <Modal className="modalError" show={showError} onHide={() => setShowError(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className={"text-danger dots"}>
                        {errorMessage}
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowError(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ne pas enregistrer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Attention les informations de la mariage ne seront pas enregsitrées. Confirmer ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Revenir à l'édition de la mariage
                    </Button>
                    <Button variant="primary" onClick={cancelWeddingAdd}>
                        Je souhaite abandonner la mariage en cours
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddWedding
