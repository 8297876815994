import React from 'react'
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

function getIdFromObj(objString) {
    var n = objString.lastIndexOf('/');
    return objString.substring(n + 1);
}

function dateConverter(dateString) {
    var date = new Date(dateString);
    return date.toLocaleDateString();
}

const WeddingTitle = (props) => {

    const weddings = useSelector((state) => state.weddings.weddings);
    const weddingID = getIdFromObj(props.weddingUri);
    let weddingObj = null;

    weddings.forEach((item, index)=>{
        if(parseInt(item.id) === parseInt(weddingID)) {
            weddingObj = item;
        }
    })

    return (
        <div className={"weddingTitleComponent"}>
            {weddingObj &&
            <Link to={'/weddings/dashboard/' + weddingObj.id}>{weddingObj.title} le {dateConverter(weddingObj.wdate)}</Link>
            }
        </div>
    )
}

export default WeddingTitle
