import React from 'react'
import {Row, Col, Badge} from 'react-bootstrap'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import {useSelector} from "react-redux";
import '@fortawesome/fontawesome-free/css/all.css';

const statusOptions = {
    1: 'En cours',
    2: 'Annulé',
    3: 'Terminé'
};

const columns = [{
    dataField: 'number',
    text: '#',
    sort: true,
    formatter: linkFormatter,
    filter: textFilter()
},{
    dataField: 'title',
    text: 'Nom',
    sort: true,
    formatter: linkFormatter,
    filter: textFilter()
},{
    dataField: 'wdate',
    text: 'Date',
    sort: true,
    formatter: dateFormatter,
    filter: dateFilter({
        withoutEmptyComparatorOption: true,
        comparators: ["="]
    })
},{
    dataField: 'wdate', // date_created todo timeConverter
    text: 'Nb jours',
    sort: true,
    formatter: nbDaysFormatter
},{
    dataField: 'status',
    text: 'Statut',
    sort: true,
    formatter: formatStatus,
    filter: selectFilter({
        options: statusOptions
    })
},{
    dataField: 'nbGuests',
    text: 'Nb invités',
    sort: true,
    filter: textFilter()
},{
    dataField: 'package',
    text: 'Forfait',
    sort: true,
    filter: textFilter()
},{
    dataField: 'place',
    text: 'Lieu',
    sort: true,
    filter: textFilter()
},{
    dataField: 'city',
    text: 'Ville',
    sort: true,
    filter: textFilter(),
    formatter: formatCityAndZip
}];

const defaultSorted = [{
    dataField: 'city',
    wedding: 'desc'
}];

function formatStatus(cell) {
    let bg = 'light';

    switch(cell) {
        case 1:
            bg = "success";
            break;
        case 2:
            bg = "warning";
            break;
        default:
            bg = "info";
    }

    return (
        <h5><Badge pill bg={bg}>{statusOptions[cell]}</Badge></h5>
    )
}

function linkFormatter(cell, row) {
    return (
        <Link to={'/weddings/dashboard/' + row.id}>{cell}</Link>
    );
}

function dateFormatter(cell, row) {
    return dateConverter(cell, row);
}

function formatCityAndZip(cell, row) {
    return row.city + ' (' + row.zipcode + ')';
}

function nbDaysFormatter(cell, row) {
    return nbDaysBeforeDate(cell);
}

function nbDaysBeforeDate(date1, date2) {

    let date_1 = new Date(date1);
    let date_2 = new Date();

    const days = (date_1, date_2) =>{
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    var nbdays = days(date_1, date_2);

    return nbdays;
}

function dateConverter(dateString, context) {

    var date = new Date(dateString);
    return date.toLocaleDateString();

}

const rowStyle = (row, rowIndex) => {
    const style = {};
    // TODO couleurs
    return style;
}

const RemoteDataTable = props => (
    <div>
        <BootstrapTable
            keyField='number'
            data={ props.data }
            columns={ columns }
            hover
            condensed
            bweddinged={ false }
            defaultSorted={ defaultSorted }
            filter={ filterFactory() }
            filterPosition="top"
            rowStyle={ rowStyle }
            //pagination={ paginationFactory() }
            noDataIndication="Aucun mariage ou problème de chargement des données"
        />
    </div>
);

const WeddingList = () => {

    const weddings = useSelector((state) => state.weddings.weddings);

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h4 className="card-title">Mariages</h4>
                            <Link to="/weddings/new" className="btn btn-primary -pull-right">Ajouter un mariage</Link>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-responsive">
                                <RemoteDataTable
                                    data={weddings}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default WeddingList
