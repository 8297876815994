import React, {useEffect, useState} from 'react'
import {Row, Col, Badge} from 'react-bootstrap'
import Card from '../../components/Card'
import WeddingTitle from '../../components/WeddingTitle'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import {useDispatch, useSelector} from "react-redux";
import '@fortawesome/fontawesome-free/css/all.css';
import {
    getContactsRequest
} from "../../requests/api";
import {
    setStoreContacts
} from "../../store/redux";

const booleanSelect = {
    true: 'Oui',
    false: 'Non'
};

function formatBoolean(cell) {

    let bg = 'light';

    switch(cell) {
        case true:
            bg = "success";
            break;
        case false:
            bg = "warning";
            break;
        default:
            bg = "danger";
    }

    return (
        <h5><Badge pill bg={bg}>{booleanSelect[cell]}</Badge></h5>
    )
}

const columns = [{
    dataField: 'lastname',
    text: 'Nom',
    sort: true,
    formatter: linkFormatter,
    filter: textFilter()
},{
    dataField: 'firstname',
    text: 'Prénom',
    sort: true,
    formatter: linkFormatter,
    filter: textFilter()
},{
    dataField: 'phone',
    text: 'Téléphone',
    sort: true,
    filter: textFilter()
},{
    dataField: 'email',
    text: 'Email',
    sort: true,
    filter: textFilter()
},{
    dataField: 'isCalled',
    text: 'Contacté ?',
    sort: true,
    formatter: formatBoolean,
    filter: selectFilter({
        options: booleanSelect
    })
},{
    dataField: 'willParticipate',
    text: 'Souhaite participer ?',
    sort: true,
    formatter: formatBoolean,
    filter: selectFilter({
        options: booleanSelect
    })
},{
    dataField: 'wedding',
    text: 'Mariage',
    sort: true,
    formatter: weddingTitleFormater,
    filter: textFilter()
},{
    dataField: 'note',
    text: 'Commentaire',
    sort: false,
    filter: textFilter()
}];

const defaultSorted = [{
    dataField: 'lastname',
    wedding: 'asc'
}];


function weddingTitleFormater(cell,row) {
    return (
        <WeddingTitle
            weddingUri={row['wedding']}
        />
    )
}

function getIdFromObj(objString) {
    var n = objString.lastIndexOf('/');
    return objString.substring(n + 1);
}

function linkFormatter(cell, row) {
    return (
        <Link to={'/weddings/' + getIdFromObj(row['wedding']) + '/contacts/' + row.id + '/edit'}>{cell}</Link>
    );
}

const rowStyle = (row, rowIndex) => {
    const style = {};
    // TODO couleurs
    return style;
}

const getContacts = async () => {

    const data = getContactsRequest();
    return data;

}
const RemoteDataTable = props => (
    <div>
        <BootstrapTable
            keyField='id'
            data={ props.data }
            columns={ columns }
            hover
            condensed
            bweddinged={ false }
            defaultSorted={ defaultSorted }
            filter={ filterFactory() }
            filterPosition="top"
            rowStyle={ rowStyle }
            //pagination={ paginationFactory() }
            noDataIndication="Aucune données ou problème de chargement des données"
        />
    </div>
);

const ContactsList = () => {

    const dispatch = useDispatch();
    const contacts = useSelector((state) => state.contacts.contacts);
    const [contactsDidLoad, setContactsDidLoad] = useState(false);

    useEffect(() => {

        if(contactsDidLoad === false) {
            getContacts(contacts)
                .then(function (data) {
                    setContactsDidLoad(true);
                    if (data !== null) {
                        dispatch(setStoreContacts(data.map((contact) => ({...contact, id: contact.id}))));
                    }
                });
        }

    }, [contacts, contactsDidLoad, dispatch])

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h4 className="card-title">Carnet de contacts</h4>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-responsive">
                                <RemoteDataTable
                                    data={contacts}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default ContactsList
