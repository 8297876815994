import React from 'react'

//router
import { Switch,Route } from 'react-router'
//layoutpages
import Default from '../layouts/dashboard/default'
import Simple from '../layouts/dashboard/simple'
import {useHistory} from "react-router-dom";

const IndexRouters = () => {

    let history = useHistory()

    const loggedUser = window.localStorage.getItem("loggedUser");

    if(loggedUser === null) {
        history.push('/auth/sign-in')
    }

    return (
        <>
            {loggedUser !== null &&
                <Switch>
                    <Route exact path="/" component={Default}></Route>
                    <Route  path="/weddings" component={Default}></Route>
                    <Route  path="/meetings" component={Default}></Route>
                    <Route  path="/contacts" component={Default}></Route>
                    <Route  path="/milestones" component={Default}></Route>
                    <Route  path="/rituals" component={Default}></Route>
                    <Route  path="/payments" component={Default}></Route>
                </Switch>
            }
            <Switch>
                <Route  path="/auth" component={Simple}></Route>
            </Switch>
        </>
    )
}

export default IndexRouters
