import React, {useEffect, useState} from 'react';
import {Row, Col, Form, Button, Modal, FormCheck} from 'react-bootstrap';
import Card from '../../components/Card';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateStorePayment, removeStorePayment} from "../../store/redux";
import {getPaymentRequest, updatePaymentRequest, deletePaymentRequest, getWeddingRequest} from "../../requests/api";

const EditPayment = (props) => {

    const weddingID = props.match.params.weddingid;
    const paymentID = props.match.params.paymentid;

    const [formPaymentMethod, setFormPaymentMethod] = useState(0);
    const [formValue, setFormValue] = useState("");
    const [formObservations, setFormObservations] = useState("");
    const [formStatus, setFormStatus] = useState(0);
    const [formDueDate, setFormDueDate] = useState(null);
    const [formIsDeposit, setFormIsDeposit] = useState(false);

    const paymentMethods = useSelector((state) => state.paymentMethods.paymentMethods);

    const dispatch = useDispatch();

    const [wedding, setWedding] = useState(null);
    const [payment, setPayment] = useState(null);

    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleClose = () => setShow(false);
    const handleCancel = () => setShow(true);
    const handleDelete= () => setShowDelete(true);
    const handleCloseDelete = () => setShow(false);

    let history =useHistory()

    const convertToDate = (dateString) => {

        var date = new Date(dateString);
        var result = date.toISOString().substr(0,10);

        // 2017-06-01T08:30
        console.log(result);
        return result;
        //return date.toLocaleDateString() + ' ' + date.toISOString().substr(11,5);

    }

    useEffect(() => {

        const getWeddingInfos = async () => {

            await getWeddingRequest(weddingID)
                .then(function(response) {
                    const wedding = response;

                    setWedding({...wedding, id: wedding.id});
                });

        }

        getWeddingInfos();

    }, [weddingID])


    useEffect(() => {

        const getPaymentInfos = async () => {

            await getPaymentRequest(paymentID)
                .then(function(response) {
                    const payment = response;

                    setPayment({...payment, id: payment.id});

                    setFormPaymentMethod(payment.paymentMethod);
                    setFormValue(payment.value);
                    setFormDueDate(convertToDate(payment.dueDate));
                    setFormIsDeposit(payment.isDeposit);
                    setFormObservations(payment.note);
                    setFormStatus(payment.status);
                });

        }

        getPaymentInfos();

    }, [paymentID])

    const cancelForm = () => {

        history.push('/weddings/' + weddingID + '/dashboard');

    }

    const getWeddingTitle = (wedding) => {
        if(wedding) {
            return 'Mariage de ' + wedding.title + ' le ' + getWeddingFullDate(wedding);
        }

        return false;
    }

    const getWeddingFullDate = (wedding) => {
        if(wedding.wdate) {
            return new Date(wedding.wdate).toLocaleDateString();
        }
        return false;
    }

    const deleteElement = async() => {

        deletePaymentRequest(paymentID)
            .then((response) => {

                if (response.name === 'AxiosError') {

                    console.log('error');
                    console.log(response);

                    setShowError(true);
                    if(response.response.data['hydra:description']) {
                        setErrorMessage(response.response.data['hydra:description']);
                    }
                    else {
                        setErrorMessage(response.message);
                    }

                    return false;

                } else {

                    dispatch(removeStorePayment(paymentID));
                    // SUCCESS
                    history.push('/weddings/dashboard/' + wedding.id);

                }
            });
    }

    const saveForm = async () => {

        const prepareObject = {
            paymentMethod: formPaymentMethod,
            dueDate: formDueDate,
            isDeposit: formIsDeposit,
            value: parseFloat(formValue),
            status: parseInt(formStatus),
            note: formObservations,
            wedding: 'weddings/' + wedding.id
        };

        updatePaymentRequest(paymentID, prepareObject)
            .then((response) => {

                if (response.name === 'AxiosError') {

                    console.log('error');
                    console.log(response);

                    setShowError(true);
                    if(response.response.data['hydra:description']) {
                        setErrorMessage(response.response.data['hydra:description']);
                    }
                    else {
                        setErrorMessage(response.message);
                    }

                    return false;

                } else {

                    dispatch(updateStorePayment(response));
                    // SUCCESS
                    history.push('/weddings/dashboard/' + wedding.id);

                }
            });
    }

    return (
        <>
            {payment &&
            <div>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <div className="card-title mb-0">
                                    <h4 className="mb-0">Modifier le paiement de {payment.value} €</h4>
                                    <h5 className="mb-0">{getWeddingTitle(wedding)}</h5>
                                </div>
                                <div className="card-action">
                                    <Button onClick={cancelForm} className="btn btn-secondary"
                                            role="button">Retour</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Informations</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="amount">Montant</Form.Label>
                                                <Form.Control type="text" id="amount" value={formValue} onChange={(event) => {
                                                    setFormValue(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="method">Mode de paiement</Form.Label>
                                                <select className="form-select" id="method" onChange={(event) => {
                                                    setFormPaymentMethod(event.target.value);
                                                }}
                                                >
                                                    <option key={""} value="0">Sélectionner un mode de paiement</option>
                                                    {paymentMethods.map((method, methodindex) => (
                                                        <option
                                                            selected={formPaymentMethod === ("/payment_methods/" + method.id)}
                                                            key={"payment" + methodindex}
                                                            value={method['@id']}>{method.title}</option>
                                                    ))}
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <fieldset className="mb-3">
                                                <legend>Est-ce un acompte ?</legend>
                                                <Form.Check className="">
                                                    <FormCheck.Input type="radio" checked={formIsDeposit === false} name="isDeposit" className="" id="isDepositFalse" onChange={(event) => {
                                                        setFormIsDeposit(false);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isDepositFalse">Non</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="mb-3 ">
                                                    <FormCheck.Input type="radio" checked={formIsDeposit === true}  name="isDeposit" className="" id="isDepositTrue" onChange={(event) => {
                                                        setFormIsDeposit(true);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isDepositTrue">Oui</FormCheck.Label>
                                                </Form.Check>
                                            </fieldset>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="duedate">Date du paiement</Form.Label>
                                                <Form.Control type="date"  id="duedate" value={formDueDate} onChange={(event) => {
                                                    setFormDueDate(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="status">Statut</Form.Label>
                                                <select className="form-select" id="status" onChange={(event) => {
                                                    setFormStatus(event.target.value);
                                                }}
                                                >
                                                    <option>Séléctionner un statut</option>
                                                    <option selected={formStatus === 1} value="1">A payer</option>
                                                    <option selected={formStatus === 2} value="2">Payé</option>
                                                    <option selected={formStatus === 3} value="3">Annulé</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Informations additionnelles</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="observations">Notes/Observations</Form.Label>
                                                <Form.Control as="textarea" rows="5" value={formObservations}
                                                              id="observations" onChange={(event) => {
                                                    setFormObservations(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="button" variant="btn btn-info" onClick={handleCancel}>Annuler</Button>{' '}
                        <Button type="button" variant="btn btn-primary" onClick={saveForm}>Enregistrer</Button>
                    </Col>
                    <Col>
                        <Button type="button" variant="btn btn-danger" onClick={handleDelete}>Supprimer</Button>
                    </Col>
                </Row>
            </div>
            }
            <Modal className="modalError" show={showError} onHide={() => setShowError(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className={"text-danger dots"}>
                        {errorMessage}
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowError(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ne pas enregistrer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Attention les informations de ce formulaire ne seront pas enregsitrées. Confirmer ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Revenir à l'édition
                    </Button>
                    <Button variant="primary" onClick={cancelForm}>
                        Je souhaite abandonner la saisie en cours
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Supprimer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Attention la suppression est définitive. Confirmer ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        Revenir à l'édition
                    </Button>
                    <Button variant="primary" onClick={deleteElement}>
                        Je souhaite supprimer cet élément
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditPayment
