import React, {useEffect, useState} from 'react';
import {Row, Col, Form, Button, Modal, FormCheck} from 'react-bootstrap';
import Card from '../../components/Card';
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {updateStoreContact, setStoreContacts} from "../../store/redux";
import {getContactRequest, updateContactRequest, getWeddingContactsRequest, getWeddingRequest} from "../../requests/api";

const EditContact = (props) => {

    const weddingID = props.match.params.weddingid;
    const contactID = props.match.params.contactid;

    const [formGender, setFormGender] = useState(1);
    const [formFirstname, setFormFirstname] = useState("");
    const [formLastname, setFormLastname] = useState("");
    const [formIsWeddingActor1, setFormIsWeddingActor1] = useState(false);
    const [formIsWeddingActor2, setFormIsWeddingActor2] = useState(false);
    const [formIsWitnessOfActor1, setFormIsWitnessOfActor1] = useState(false);
    const [formIsWitnessOfActor2, setFormIsWitnessOfActor2] = useState(false);
    const [formPhone, setFormPhone] = useState("");
    const [formEmail, setFormEmail] = useState("");
    const [formAddress, setFormAddress] = useState("");
    const [formCity, setFormCity] = useState("");
    const [formZipcode, setFormZipcode] = useState("");
    const [formRelationContact, setFormRelationContact] = useState(null);
    const [formRelationType, setFormRelationType] = useState("");
    const [formIsCalled, setFormIsCalled] = useState(false);
    const [formWillParticipate, setFormWillParticipate] = useState(false);
    const [formObservations, setFormObservations] = useState("");

    const dispatch = useDispatch();

    const [wedding, setWedding] = useState(null);
    const [contact, setContact] = useState(null);
    const [weddingContacts, setWeddingContacts] = useState([]);

    const [show, setShow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const handleClose = () => setShow(false);
    const handleCancel = () => setShow(true);

    let history =useHistory()

    useEffect(() => {

        const getWeddingInfos = async () => {

            await getWeddingRequest(weddingID)
                .then(function(response) {
                    const wedding = response;

                    setWedding({...wedding, id: wedding.id});

                    // Contacts du mariage
                    getWeddingContactsRequest(wedding.id)
                        .then((response) => {

                            response.forEach(function(contact, index) {

                                if(contact.relationContact) {
                                    let relationContactInfos = response.find(item => item['@id'] === contact.relationContact)
                                    if(relationContactInfos) {
                                        response[index]['relationContactInfos'] = relationContactInfos;
                                    }
                                }

                            });

                            setWeddingContacts(response.map((item) => ({...item, id: item.id})));
                            dispatch(setStoreContacts(response.map((item) => ({...item, id: item.id}))));

                            console.log(setWeddingContacts)
                        });

                });

        }

        getWeddingInfos();

    }, [weddingID, dispatch])


    useEffect(() => {

        const getContactInfos = async () => {

            await getContactRequest(contactID)
                .then(function(response) {
                    const contact = response;

                    setContact({...contact, id: contact.id});

                    setFormGender(contact.gender);
                    setFormFirstname(contact.firstname);
                    setFormLastname(contact.lastname);
                    setFormIsWeddingActor1(contact.isWeddingActor1);
                    setFormIsWeddingActor2(contact.isWeddingActor2);
                    setFormIsWitnessOfActor1(contact.isWitnessOfActor1);
                    setFormIsWitnessOfActor2(contact.isWitnessOfActor2);
                    setFormPhone(contact.phone);
                    setFormEmail(contact.email);
                    setFormAddress(contact.address);
                    setFormCity(contact.city);
                    setFormZipcode(contact.zipcode);
                    setFormRelationContact(contact.relationContact);
                    setFormRelationType(contact.relationType);
                    setFormIsCalled(contact.isCalled);
                    setFormWillParticipate(contact.willParticipate);
                    setFormObservations(contact.note);

                });

        }

        getContactInfos();

    }, [contactID])

    const cancelForm = () => {

        history.push('/weddings/' + weddingID + '/dashboard');

    }

    const getContactFullName = (contact) => {
        if(contact && contact.firstname && contact.lastname) {
            return contact.firstname + ' ' + contact.lastname.toUpperCase();
        }
        return false;
    }

    const getWeddingTitle = (wedding) => {
        if(wedding) {
            return 'Mariage de ' + wedding.title + ' le ' + getWeddingFullDate(wedding);
        }

        return false;
    }

    const getWeddingFullDate = (wedding) => {
        if(wedding.wdate) {
            return new Date(wedding.wdate).toLocaleDateString();
        }
        return false;
    }

    const saveForm = async () => {

        const prepareObject = {
            gender: formGender,
            firstname: formFirstname,
            lastname: formLastname,
            isWeddingActor1: formIsWeddingActor1,
            isWeddingActor2: formIsWeddingActor2,
            isWitnessOfActor1: formIsWitnessOfActor1,
            isWitnessOfActor2: formIsWitnessOfActor2,
            phone: formPhone,
            email: formEmail,
            address: formAddress,
            city: formCity,
            zipcode: formZipcode,
            relationContact: formRelationContact,
            relationType: formRelationType,
            isCalled: formIsCalled,
            willParticipate: formWillParticipate,
            note: formObservations
        };

        updateContactRequest(contactID, prepareObject)
            .then((response) => {

                if (response.name === 'AxiosError') {

                    console.log('error');
                    console.log(response);

                    setShowError(true);
                    if(response.response.data['hydra:description']) {
                        setErrorMessage(response.response.data['hydra:description']);
                    }
                    else {
                        setErrorMessage(response.message);
                    }

                    return false;

                } else {

                    dispatch(updateStoreContact(response));
                    // SUCCESS
                    history.push('/weddings/dashboard/' + wedding.id);

                }
            });
    }

    return (
        <>
            <div>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <div className="card-title mb-0">
                                    <h4 className="mb-0">Modifier le contact {getContactFullName(contact)}</h4>
                                    <h5 className="mb-0">{getWeddingTitle(wedding)}</h5>
                                </div>
                                <div className="card-action">
                                    <Button onClick={cancelForm} className="btn btn-secondary" role="button">Retour</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Identité</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="gender">Genre</Form.Label>
                                                <select className="form-select" id="gender" onChange={(event) => {
                                                    setFormGender(event.target.value);
                                                }}
                                                >
                                                    <option selected={formGender === 1} value="1">Homme</option>
                                                    <option selected={formGender === 2} value="2">Femme</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="firstname">Prénom</Form.Label>
                                                <Form.Control type="text" value={formFirstname} id="firstname" onChange={(event) => {
                                                    setFormFirstname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="lastname">Nom</Form.Label>
                                                <Form.Control type="text" value={formLastname}  id="lastname" onChange={(event) => {
                                                    setFormLastname(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="phone">Téléphone</Form.Label>
                                                <Form.Control type="text" value={formPhone} id="phone" onChange={(event) => {
                                                    setFormPhone(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="email">Email</Form.Label>
                                                <Form.Control type="text" value={formEmail}  id="email" onChange={(event) => {
                                                    setFormEmail(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="phone">Adresse</Form.Label>
                                                <Form.Control type="text" value={formAddress} id="address" onChange={(event) => {
                                                    setFormAddress(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="8">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="city">Ville</Form.Label>
                                                <Form.Control type="text" value={formCity} id="city" onChange={(event) => {
                                                    setFormCity(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="zipcode">Code postal</Form.Label>
                                                <Form.Control type="text" value={formZipcode}  id="zipcode" onChange={(event) => {
                                                    setFormZipcode(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Relation</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>

                                    <Row>
                                        <Col>
                                            <fieldset className="mb-3">
                                                <legend>Est-ce le marié (1) ?</legend>
                                                <Form.Check className="">
                                                    <FormCheck.Input type="radio" checked={formIsWeddingActor1 === false} name="isWeddingActor1" className="" id="isWeddingActor1False" onChange={(event) => {
                                                        setFormIsWeddingActor1(false);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isWeddingActor1False">Non</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="mb-3 ">
                                                    <FormCheck.Input type="radio" checked={formIsWeddingActor1 === true} name="isWeddingActor1" className="" id="isWeddingActor1True" onChange={(event) => {
                                                        setFormIsWeddingActor1(true);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isWeddingActor1True">Oui</FormCheck.Label>
                                                </Form.Check>
                                            </fieldset>
                                        </Col>
                                        <Col>
                                            <fieldset className="mb-3">
                                                <legend>Est-ce le marié (2) ?</legend>
                                                <Form.Check className="">
                                                    <FormCheck.Input type="radio" checked={formIsWeddingActor2 === false} name="isWeddingActor2" className="" id="isWeddingActor2False" onChange={(event) => {
                                                        setFormIsWeddingActor2(false);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isWeddingActor2False">Non</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="mb-3 ">
                                                    <FormCheck.Input type="radio" checked={formIsWeddingActor2 === true} name="isWeddingActor2" className="" id="isWeddingActor2True" onChange={(event) => {
                                                        setFormIsWeddingActor2(true);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isWeddingActor2True">Oui</FormCheck.Label>
                                                </Form.Check>
                                            </fieldset>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <fieldset className="mb-3">
                                                <legend>Est-ce un témoin du marié (1) ?</legend>
                                                <Form.Check className="">
                                                    <FormCheck.Input type="radio" checked={formIsWitnessOfActor1 === false} name="isWitnessWeddingActor1" className="" id="isWitnessWeddingActor1False" onChange={(event) => {
                                                        setFormIsWitnessOfActor1(false);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isWitnessWeddingActor1False">Non</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="mb-3 ">
                                                    <FormCheck.Input type="radio" checked={formIsWitnessOfActor1 === true} name="isWitnessWeddingActor1" className="" id="isWitnessWeddingActor1True" onChange={(event) => {
                                                        setFormIsWitnessOfActor1(true);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isWitnessWeddingActor1True">Oui</FormCheck.Label>
                                                </Form.Check>
                                            </fieldset>
                                        </Col>
                                        <Col>
                                            <fieldset className="mb-3">
                                                <legend>Est-ce un témoin du marié (2) ?</legend>
                                                <Form.Check className="">
                                                    <FormCheck.Input type="radio" checked={formIsWitnessOfActor2 === false} name="isWitnessWeddingActor2" className="" id="isWitnessWeddingActor2False" onChange={(event) => {
                                                        setFormIsWitnessOfActor2(false);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isWitnessWeddingActor2False">Non</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="mb-3 ">
                                                    <FormCheck.Input type="radio" checked={formIsWitnessOfActor2 === true} name="isWitnessWeddingActor2" className="" id="isWitnessWeddingActor2True" onChange={(event) => {
                                                        setFormIsWitnessOfActor2(true);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isWitnessWeddingActor2True">Oui</FormCheck.Label>
                                                </Form.Check>
                                            </fieldset>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="gender">Est de contact de</Form.Label>
                                                <select className="form-select" id="relationContact" onChange={(event) => {
                                                    setFormRelationContact(event.target.value);
                                                }}
                                                >
                                                <option key={""} value="0">Sélectionner un contact</option>
                                                {weddingContacts.map((weddingcontact,contactindex) => (
                                                    <option selected={formRelationContact === ("/wedding_contacts/" + weddingcontact.id)}  key={"contact" + contactindex} value={'wedding_contacts/' + weddingcontact.id}>{getContactFullName(weddingcontact)}</option>
                                                ))}
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="relationType">Relation avec ce contact</Form.Label>
                                                <Form.Control type="text" value={formRelationType} id="relationType" onChange={(event) => {
                                                    setFormRelationType(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm="12" lg="6">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Participation</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <fieldset className="mb-3">
                                                <legend>A déjà été contacté ?</legend>
                                                <Form.Check className="">
                                                    <FormCheck.Input type="radio" checked={formIsCalled === false} name="isCalled" className="" id="isCalledFalse" onChange={(event) => {
                                                        setFormIsCalled(false);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isCalledFalse">Non</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="mb-3 ">
                                                    <FormCheck.Input type="radio" checked={formIsCalled === true} name="isCalled" className="" id="isCalledTrue" onChange={(event) => {
                                                        setFormIsCalled(true);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="isCalledTrue">Oui</FormCheck.Label>
                                                </Form.Check>
                                            </fieldset>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <fieldset className="mb-3">
                                                <legend>Souhaite participer ?</legend>
                                                <Form.Check className="">
                                                    <FormCheck.Input checked={formWillParticipate === false} type="radio" name="willParticipate" className="" id="willParticipateFalse" onChange={(event) => {
                                                        setFormWillParticipate(false);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="willParticipateFalse">Non</FormCheck.Label>
                                                </Form.Check>
                                                <Form.Check className="mb-3 ">
                                                    <FormCheck.Input checked={formWillParticipate === true} type="radio" name="willParticipate" className="" id="willParticipateTrue" onChange={(event) => {
                                                        setFormWillParticipate(true);
                                                    }} />
                                                    <FormCheck.Label className="" htmlFor="willParticipateTrue">Oui</FormCheck.Label>
                                                </Form.Check>
                                            </fieldset>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">Informations additionnelles</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="observations">Notes/Observations</Form.Label>
                                                <Form.Control as="textarea" rows="5" value={formObservations} id="observations" onChange={(event) => {
                                                    setFormObservations(event.target.value);
                                                }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button type="button" variant="btn btn-danger" onClick={handleCancel}>Annuler</Button>{' '}
                        <Button type="button" variant="btn btn-primary" onClick={saveForm}>Enregistrer</Button>
                    </Col>
                </Row>
            </div>
            <Modal className="modalError" show={showError} onHide={() => setShowError(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className={"text-danger dots"}>
                        {errorMessage}
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowError(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Ne pas enregistrer</Modal.Title>
                </Modal.Header>
                <Modal.Body>Attention les informations de ce formulaire ne seront pas enregsitrées. Confirmer ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Revenir à l'édition
                    </Button>
                    <Button variant="primary" onClick={cancelForm}>
                        Je souhaite abandonner la saisie en cours
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditContact
