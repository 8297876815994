import React, {useEffect} from 'react'

//header
import Header from '../../components/partials/dashboard/HeaderStyle/header'
//subheader
import SubHeader from '../../components/partials/dashboard/HeaderStyle/sub-header'
//sidebar
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar'
//footer
import Footer from '../../components/partials/dashboard/FooterStyle/footer'
//default
import DefaultRouter from '../../router/default-router'

import Loader from '../../components/Loader'

import {useHistory} from "react-router-dom";


const Default = (props) => {

    let history =useHistory()

    let subHeaderTitle = '';
    let subHeaderSubTitle = '';
    let subHeaderSize = "small";
    let today = new Date();

    if(props.location.pathname === "/") {
        subHeaderTitle = "Tableau de bord";
        subHeaderSubTitle = today.toLocaleDateString()
        subHeaderSize = "big";
    }

    useEffect(() => {

        // check logged user

        const loggedUser = window.localStorage.getItem('loggedUser');
        if (loggedUser === "null" || loggedUser === '') {
            history.push('/auth/sign-in');
        }

    });

    return (
        <>
            <Loader/>
            <Sidebar />
                <main className="main-content">
                    <div className="position-relative">
                        <Header />
                        <SubHeader
                            title={subHeaderTitle}
                            subTitle={subHeaderSubTitle}
                            size={subHeaderSize}
                        />

                    </div>
                    <div className="py-0 conatiner-fluid content-inner mt-n5">
                        <DefaultRouter />
                    </div>
                    <Footer />
                </main>
        </>
    )
}

export default Default;
