import React from 'react'

import logoImage from '../../../assets/images/logo/logo.png';
import {Image} from "react-bootstrap";

const Logo = (props ) => {

    return (
        <>
            <Image src={logoImage} style={{width:"100%",marginBottom: "0 !important"}} className={"img-fluid mb-4"} alt=""/>
        </>
        )
}

export default Logo
