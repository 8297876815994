import React, {useEffect, useState} from 'react'
import Index from '../views/home/index'
import {Switch, Route} from 'react-router-dom'

//TransitionGroup
import {TransitionGroup,CSSTransition} from "react-transition-group";

//Wedding
import WeddingList from '../views/weddings/index';
import AddWedding from '../views/weddings/add';
import EditWedding from '../views/weddings/edit';
import WeddingDashboard from '../views/weddings/dashboard';

//Contact
import ContactList from '../views/contacts/index'
import AddContact from '../views/contacts/add'
import EditContact from '../views/contacts/edit';

//Rituel
import RitualList from '../views/rituals/index'
import AddRitual from '../views/rituals/add'
import EditRitual from '../views/rituals/edit'

//Prestataires
import AddProvider from '../views/providers/add'
import EditProvider from '../views/providers/edit'

//Meeting
import MeetingList from '../views/meetings/index'
import AddMeeting from '../views/meetings/add'
import EditMeeting from '../views/meetings/edit'

//Payments
import PaymentList from '../views/payments/index'
import AddPayment from '../views/payments/add'
import EditPayment from '../views/payments/edit'

//Milestone
import MilestoneList from '../views/milestones/index'
import AddMilestone from '../views/milestones/add'
import EditMilestone from '../views/milestones/edit'

import {setStoreWeddings, setStoreRitualTypes, setStoreProviderTypes, setStorePaymentMethods} from "../store/redux";
import {useDispatch, useSelector} from "react-redux";
import { getWeddingsRequest, getRitualTypesRequest, getProviderTypesRequest, getPaymentMethodsRequest } from '../requests/api';


const getWeddings = async (weddings) => {

    if(!weddings.length) {
        const data = getWeddingsRequest();
        return data;
    }
    else {
        console.log('loading weddings from redux');
        return null;
    }

}

const getRitualTypes = async (ritualTypes) => {

    if(!ritualTypes.length) {
        const data = getRitualTypesRequest();
        return data;
    }
    else {
        console.log('loading ritualTypes from redux');
        return null;
    }

}

const getProviderTypes = async (providerTypes) => {

    if(!providerTypes.length) {
        const data = getProviderTypesRequest();
        return data;
    }
    else {
        console.log('loading providerTypes from redux');
        return null;
    }

}

const getPaymentMethods = async (paymentMethods) => {

    if(!paymentMethods.length) {
        const data = getPaymentMethodsRequest();
        return data;
    }
    else {
        console.log('loading paymentMethods from redux');
        return null;
    }

}

const DefaultRouter = () => {

    const dispatch = useDispatch();
    const weddings = useSelector((state) => state.weddings.weddings);
    const ritualTypes = useSelector((state) => state.ritualTypes.ritualTypes);
    const providerTypes = useSelector((state) => state.providerTypes.providerTypes);
    const paymentMethods = useSelector((state) => state.paymentMethods.paymentMethods);
    const [weddingsDidLoad, setWeddingsDidLoad] = useState(false);
    const [ritualTypesDidLoad, setRitualTypesDidLoad] = useState(false)
    const [providerTypesDidLoad, setProviderTypesDidLoad] = useState(false)
    const [paymentMethodsDidLoad, setPaymentMethodsDidLoad] = useState(false)

    useEffect(() => {

        if (weddingsDidLoad === false) {
            setWeddingsDidLoad(true);
            getWeddings(weddings)
                .then(function (data) {
                    if (data !== null) {
                        dispatch(setStoreWeddings(data.map((wedding) => ({...wedding, id: wedding.id}))));
                    }
                });
        }


        if (ritualTypesDidLoad === false) {
            setRitualTypesDidLoad(true);
            getRitualTypes(ritualTypes)
                .then(function (data) {
                    if (data !== null) {
                        dispatch(setStoreRitualTypes(data.map((ritualType) => ({...ritualType, id: ritualType.id}))));
                    }
                });
        }

        if (providerTypesDidLoad === false) {
            setProviderTypesDidLoad(true);
            getProviderTypes(providerTypes)
                .then(function (data) {
                    if (data !== null) {
                        dispatch(setStoreProviderTypes(data.map((providerType) => ({
                            ...providerType,
                            id: providerType.id
                        }))));
                    }
                });
        }

        if (paymentMethodsDidLoad === false) {
            setPaymentMethodsDidLoad(true);
            getPaymentMethods(paymentMethods)
                .then(function (data) {
                    if (data !== null) {
                        dispatch(setStorePaymentMethods(data.map((paymentMethod) => ({
                            ...paymentMethod,
                            id: paymentMethod.id
                        }))));
                    }
                });
        }

    }, [weddings, ritualTypes, providerTypes, paymentMethods, dispatch, paymentMethodsDidLoad, providerTypesDidLoad, ritualTypesDidLoad, weddingsDidLoad])

    return (
        <TransitionGroup>
            <CSSTransition classNames="fadein" timeout={300}>
                <Switch>
                    {/* home dashboard */}
                    <Route path="/" exact component={Index}/>
                    {/* mariages */}
                    <Route path="/weddings" exact component={WeddingList}/>
                    <Route path="/weddings/dashboard/:id" exact component={WeddingDashboard}/>
                    <Route path="/weddings/new" exact component={AddWedding}/>
                    <Route path="/weddings/:weddingid/edit" exact component={EditWedding}/>
                    {/* contacts */}
                    <Route path="/contacts" exact component={ContactList}/>
                    <Route path="/weddings/:weddingid/contacts/new" exact component={AddContact}/>
                    <Route path="/weddings/:weddingid/contacts/:contactid/edit" exact component={EditContact}/>
                    {/* rituels */}
                    <Route path="/rituals" exact component={RitualList}/>
                    <Route path="/weddings/:weddingid/rituals/new" exact component={AddRitual}/>
                    <Route path="/weddings/:weddingid/rituals/:ritualid/edit" exact component={EditRitual}/>
                    {/* meetings */}
                    <Route path="/meetings" exact component={MeetingList}/>
                    <Route path="/weddings/:weddingid/meetings/new" exact component={AddMeeting}/>
                    <Route path="/weddings/:weddingid/meetings/:meetingid/edit" exact component={EditMeeting}/>
                    {/* milestones */}
                    <Route path="/milestones" exact component={MilestoneList}/>
                    <Route path="/weddings/:weddingid/milestones/new" exact component={AddMilestone}/>
                    <Route path="/weddings/:weddingid/milestones/:milestoneid/edit" exact component={EditMilestone}/>
                    {/* providers */}
                    <Route path="/weddings/:weddingid/providers/new" exact component={AddProvider}/>
                    <Route path="/weddings/:weddingid/providers/:providerid/edit" exact component={EditProvider}/>
                    {/* payments */}
                    <Route path="/payments" exact component={PaymentList}/>
                    <Route path="/weddings/:weddingid/payments/new" exact component={AddPayment}/>
                    <Route path="/weddings/:weddingid/payments/:paymentid/edit" exact component={EditPayment}/>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default DefaultRouter
