import React, {useEffect, useState} from 'react'
import {Row, Col, Badge} from 'react-bootstrap'
import Card from '../../components/Card'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import {useDispatch, useSelector} from "react-redux";
import '@fortawesome/fontawesome-free/css/all.css';
import {
    getRitualsRequest
} from "../../requests/api";
import {
    setStoreRituals
} from "../../store/redux";
import WeddingTitle from "../../components/WeddingTitle";

const statusOptions = {
    1: 'A valider',
    2: 'Confirmé',
    3: 'Annulé'
};

const ritualTypesOptions = {
    1: 'A valider',
    2: 'Confirmé',
    3: 'Annulé'
};


const columns = [{
    dataField: 'type',
    text: 'Titre',
    sort: false,
    formatter: ritualTypeTitleFormater,
    filter: selectFilter({
        options: ritualTypesOptions
    })
},{
    dataField: 'wedding',
    text: 'Mariage',
    sort: true,
    formatter: weddingTitleFormater,
    filter: textFilter()
},{
    dataField: 'status',
    text: 'Statut',
    sort: true,
    formatter: formatStatus,
    filter: selectFilter({
        options: statusOptions
    })
},{
    dataField: 'note',
    text: 'Commentaire',
    sort: false,
    filter: textFilter()
}];

const defaultSorted = [{
    dataField: 'type',
    wedding: 'asc'
}];

function formatStatus(cell) {
    let bg = 'light';

    switch(cell) {
        case 1:
            bg = "danger";
            break;
        case 2:
            bg = "warning";
            break;
        default:
            bg = "success";
    }

    return (
        <h5><Badge pill bg={bg}>{statusOptions[cell]}</Badge></h5>
    )
}

function ritualTypeTitleFormater(cell,row) {

    // TODO
    return 'TODO';
}

function weddingTitleFormater(cell,row) {
    return (
        <WeddingTitle
            weddingUri={row['wedding']}
        />
    )
}


const rowStyle = (row, rowIndex) => {
    const style = {};
    // TODO couleurs
    return style;
}

const getRituals = async () => {

    const data = getRitualsRequest();
    return data;

}
const RemoteDataTable = props => (
    <div>
        <BootstrapTable
            keyField='id'
            data={ props.data }
            columns={ columns }
            hover
            condensed
            bweddinged={ false }
            defaultSorted={ defaultSorted }
            filter={ filterFactory() }
            filterPosition="top"
            rowStyle={ rowStyle }
            //pagination={ paginationFactory() }
            noDataIndication="Aucune données ou problème de chargement des données"
        />
    </div>
);

const RitualList = () => {

    const dispatch = useDispatch();
    const rituals = useSelector((state) => state.rituals.rituals);
    const [ritualsDidLoad, setRitualsDidLoad] = useState(false);

    useEffect(() => {

        if(ritualsDidLoad === false) {
            getRituals(rituals)
                .then(function (data) {
                    setRitualsDidLoad(true);
                    if (data !== null) {
                        dispatch(setStoreRituals(data.map((ritual) => ({...ritual, id: ritual.id}))));
                    }
                });
        }

    }, [rituals, ritualsDidLoad, dispatch])

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h4 className="card-title">Rituels</h4>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-responsive">
                                <RemoteDataTable
                                    data={rituals}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default RitualList
