import {configureStore, createSlice} from "@reduxjs/toolkit";

const weddingsSlice = createSlice({
    name: "weddings",
    initialState: {
        weddings: [],
    },
    reducers: {
        setStoreWeddings: (state, { payload }) => {
            state.weddings = payload;

            console.log('REDUX set weddings');
            console.log(state.weddings);
        },
        addStoreWedding: (state, { payload }) => {

            console.log('REDUX add wedding');
            console.log(payload);

            state.weddings.push(payload);
        },
        updateStoreWedding: (state, { payload }) => {

            console.log('REDUX update wedding');
            console.log(payload);
            console.log('weddings in redux =>');
            console.log(state.weddings);

            // On identifie la commande en question dans le store
            let wedding = state.weddings.find(item => item.id === payload.id)
            if(wedding) {
                wedding = payload;
                var id = wedding.id;
                // On retirer l'élément du store
                state.weddings = state.weddings.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.weddings.push(payload);
            }
            else {
                console.log('REDUX error cant find wedding in store');
            }

        },
        removeStoreWedding: (state, { payload }) => {
            const id = payload.id;
            state.weddings = state.weddings.filter(item => item.id !== id)
        }
    }
});


const contactsSlice = createSlice({
    name: "contacts",
    initialState: {
        contacts: [],
    },
    reducers: {
        setStoreContacts: (state, { payload }) => {
            state.contacts = payload;

            console.log('REDUX set contacts');
            console.log(state.contacts);
        },
        addStoreContact: (state, { payload }) => {

            console.log('REDUX add contact');
            console.log(payload);

            state.contacts.push(payload);
        },
        updateStoreContact: (state, { payload }) => {

            console.log('REDUX update contact');
            console.log(payload);
            console.log('contacts in redux =>');
            console.log(state.contacts);

            let contact = state.contacts.find(item => item.id === payload.id)
            if(contact) {
                contact = payload;
                var id = contact.id;
                // On retirer l'élément du store
                state.contacts = state.contacts.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.contacts.push(payload);
            }
            else {
                console.log('REDUX error cant find contact in store');
            }

        },
        removeStoreContact: (state, { payload }) => {
            const id = payload.id;
            state.contacts = state.contacts.filter(item => item.id !== id)
        }
    }
});


const ritualsSlice = createSlice({
    name: "rituals",
    initialState: {
        rituals: [],
    },
    reducers: {
        setStoreRituals: (state, { payload }) => {
            state.rituals = payload;

            console.log('REDUX set rituals');
            console.log(state.rituals);
        },
        addStoreRitual: (state, { payload }) => {

            console.log('REDUX add ritual');
            console.log(payload);

            state.rituals.push(payload);
        },
        updateStoreRitual: (state, { payload }) => {

            console.log('REDUX update ritual');
            console.log(payload);
            console.log('rituals in redux =>');
            console.log(state.rituals);

            let ritual = state.rituals.find(item => item.id === payload.id)
            if(ritual) {
                ritual = payload;
                var id = ritual.id;
                // On retirer l'élément du store
                state.rituals = state.rituals.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.rituals.push(payload);
            }
            else {
                console.log('REDUX error cant find ritual in store');
            }

        },
        removeStoreRitual: (state, { payload }) => {
            const id = payload.id;
            state.rituals = state.rituals.filter(item => item.id !== id)
        }
    }
});


const ritualTypesSlice = createSlice({
    name: "ritualTypes",
    initialState: {
        ritualTypes: [],
    },
    reducers: {
        setStoreRitualTypes: (state, { payload }) => {
            state.ritualTypes = payload;

            console.log('REDUX set ritualTypes');
            console.log(state.ritualTypes);
        },
        addStoreRitualType: (state, { payload }) => {

            console.log('REDUX add ritualType');
            console.log(payload);

            state.ritualTypes.push(payload);
        },
        updateStoreRitualType: (state, { payload }) => {

            console.log('REDUX update ritualType');
            console.log(payload);
            console.log('ritualTypes in redux =>');
            console.log(state.ritualTypes);

            let ritualType = state.ritualTypes.find(item => item.id === payload.id)
            if(ritualType) {
                ritualType = payload;
                var id = ritualType.id;
                // On retirer l'élément du store
                state.ritualTypes = state.ritualTypes.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.ritualTypes.push(payload);
            }
            else {
                console.log('REDUX error cant find ritualType in store');
            }

        },
        removeStoreRitualType: (state, { elementID }) => {
            const id = elementID;
            state.ritualTypes = state.ritualTypes.filter(item => item.id !== id)
        }
    }
});

const paymentsSlice = createSlice({
    name: "payments",
    initialState: {
        payments: [],
    },
    reducers: {
        setStorePayments: (state, { payload }) => {
            state.payments = payload;

            console.log('REDUX set payments');
            console.log(state.payments);
        },
        addStorePayment: (state, { payload }) => {

            console.log('REDUX add payment');
            console.log(payload);

            state.payments.push(payload);
        },
        updateStorePayment: (state, { payload }) => {

            console.log('REDUX update payment');
            console.log(payload);
            console.log('payments in redux =>');
            console.log(state.payments);

            let payment = state.payments.find(item => item.id === payload.id)
            if(payment) {
                payment = payload;
                var id = payment.id;
                // On retirer l'élément du store
                state.payments = state.payments.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.payments.push(payload);
            }
            else {
                console.log('REDUX error cant find payment in store');
            }

        },
        removeStorePayment: (state, { payload }) => {
            const id = payload.id;
            state.payments = state.payments.filter(item => item.id !== id)
        }
    }
});

const paymentMethodsSlice = createSlice({
    name: "paymentMethods",
    initialState: {
        paymentMethods: [],
    },
    reducers: {
        setStorePaymentMethods: (state, { payload }) => {
            state.paymentMethods = payload;

            console.log('REDUX set paymentMethods');
            console.log(state.paymentMethods);
        },
        addStorePaymentMethod: (state, { payload }) => {

            console.log('REDUX add paymentMethod');
            console.log(payload);

            state.paymentMethods.push(payload);
        },
        updateStorePaymentMethod: (state, { payload }) => {

            console.log('REDUX update paymentMethod');
            console.log(payload);
            console.log('paymentMethods in redux =>');
            console.log(state.paymentMethods);

            let paymentMethod = state.paymentMethods.find(item => item.id === payload.id)
            if(paymentMethod) {
                paymentMethod = payload;
                var id = paymentMethod.id;
                // On retirer l'élément du store
                state.paymentMethods = state.paymentMethods.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.paymentMethods.push(payload);
            }
            else {
                console.log('REDUX error cant find paymentMethod in store');
            }

        },
        removeStorePaymentMethod: (state, { elementID }) => {
            const id = elementID;
            state.paymentMethods = state.paymentMethods.filter(item => item.id !== id)
        }
    }
});

const meetingsSlice = createSlice({
    name: "meetings",
    initialState: {
        meetings: [],
    },
    reducers: {
        setStoreMeetings: (state, { payload }) => {
            state.meetings = payload;

            console.log('REDUX set meetings');
            console.log(state.meetings);
        },
        addStoreMeeting: (state, { payload }) => {

            console.log('REDUX add meeting');
            console.log(payload);

            state.meetings.push(payload);
        },
        updateStoreMeeting: (state, { payload }) => {

            console.log('REDUX update meeting');
            console.log(payload);
            console.log('meetings in redux =>');
            console.log(state.meetings);

            let meeting = state.meetings.find(item => item.id === payload.id)
            if(meeting) {
                meeting = payload;
                var id = meeting.id;
                // On retirer l'élément du store
                state.meetings = state.meetings.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.meetings.push(payload);
            }
            else {
                console.log('REDUX error cant find meeting in store');
            }

        },
        removeStoreMeeting: (state, { payload }) => {
            const id = payload.id;
            state.meetings = state.meetings.filter(item => item.id !== id)
        }
    }
});

const milestonesSlice = createSlice({
    name: "milestones",
    initialState: {
        milestones: [],
    },
    reducers: {
        setStoreMilestones: (state, { payload }) => {
            state.milestones = payload;

            console.log('REDUX set milestones');
            console.log(state.milestones);
        },
        addStoreMilestone: (state, { payload }) => {

            console.log('REDUX add milestone');
            console.log(payload);

            state.milestones.push(payload);
        },
        updateStoreMilestone: (state, { payload }) => {

            console.log('REDUX update milestone');
            console.log(payload);
            console.log('milestones in redux =>');
            console.log(state.milestones);

            let milestone = state.milestones.find(item => item.id === payload.id)
            if(milestone) {
                milestone = payload;
                var id = milestone.id;
                // On retirer l'élément du store
                state.milestones = state.milestones.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.milestones.push(payload);
            }
            else {
                console.log('REDUX error cant find milestone in store');
            }

        },
        removeStoreMilestone: (state, { payload }) => {
            const id = payload.id;
            state.milestones = state.milestones.filter(item => item.id !== id)
        }
    }
});


const providersSlice = createSlice({
    name: "providers",
    initialState: {
        providers: [],
    },
    reducers: {
        setStoreProviders: (state, { payload }) => {
            state.providers = payload;

            console.log('REDUX set providers');
            console.log(state.providers);
        },
        addStoreProvider: (state, { payload }) => {

            console.log('REDUX add provider');
            console.log(payload);

            state.providers.push(payload);
        },
        updateStoreProvider: (state, { payload }) => {

            console.log('REDUX update provider');
            console.log(payload);
            console.log('providers in redux =>');
            console.log(state.providers);

            let provider = state.providers.find(item => item.id === payload.id)
            if(provider) {
                provider = payload;
                var id = provider.id;
                // On retirer l'élément du store
                state.providers = state.providers.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.providers.push(payload);
            }
            else {
                console.log('REDUX error cant find provider in store');
            }

        },
        removeStoreProvider: (state, { payload }) => {
            const id = payload.id;
            state.providers = state.providers.filter(item => item.id !== id)
        }
    }
});


const providerTypesSlice = createSlice({
    name: "providerTypes",
    initialState: {
        providerTypes: [],
    },
    reducers: {
        setStoreProviderTypes: (state, { payload }) => {
            state.providerTypes = payload;

            console.log('REDUX set providerTypes');
            console.log(state.providerTypes);
        },
        addStoreProviderType: (state, { payload }) => {

            console.log('REDUX add providerType');
            console.log(payload);

            state.providerTypes.push(payload);
        },
        updateStoreProviderType: (state, { payload }) => {

            console.log('REDUX update providerType');
            console.log(payload);
            console.log('providerTypes in redux =>');
            console.log(state.providerTypes);

            let providerType = state.providerTypes.find(item => item.id === payload.id)
            if(providerType) {
                providerType = payload;
                var id = providerType.id;
                // On retirer l'élément du store
                state.providerTypes = state.providerTypes.filter(item => item.id !== id)
                // On ajoute le nouveau
                state.providerTypes.push(payload);
            }
            else {
                console.log('REDUX error cant find providerType in store');
            }

        },
        removeStoreProviderType: (state, { elementID }) => {
            const id = elementID;
            state.providerTypes = state.providerTypes.filter(item => item.id !== id)
        }
    }
});

const loggedUserSlice = createSlice({
    name: "loggedUser",
    initialState: {
        loggedUser: null,
    },
    reducers: {
        setLoggedUser: (state, { payload }) => {
            state.loggedUser = payload;
            window.localStorage.setItem('loggedUser', payload);
        },
        clearLoggedUser: (state, { payload }) => {
            state.loggedUser = null;
            window.localStorage.removeItem('loggedUser');
            window.localStorage.removeItem('accessToken');
            window.localStorage.removeItem('refreshToken');
        },
    }
});

export const { setStoreWeddings, addStoreWedding, updateStoreWedding, removeStoreWedding } = weddingsSlice.actions;
export const { setStoreContacts, addStoreContact, updateStoreContact, removeStoreContact } = contactsSlice.actions;
export const { setStoreRituals, addStoreRitual, updateStoreRitual, removeStoreRitual } = ritualsSlice.actions;
export const { setStoreProviders, addStoreProvider, updateStoreProvider, removeStoreProvider } = providersSlice.actions;
export const { setStoreMeetings, addStoreMeeting, updateStoreMeeting, removeStoreMeeting } = meetingsSlice.actions;
export const { setStoreMilestones, addStoreMilestone, updateStoreMilestone, removeStoreMilestone } = milestonesSlice.actions;
export const { setStoreRitualTypes, addStoreRitualType, updateStoreRitualType, removeStoreRitualType } = ritualTypesSlice.actions;
export const { setStoreProviderTypes, addStoreProviderType, updateStoreProviderType, removeStoreProviderType } = providerTypesSlice.actions;
export const { setStorePayments, addStorePayment, updateStorePayment, removeStorePayment } = paymentsSlice.actions;
export const { setStorePaymentMethods, addStorePaymentMethod, updateStorePaymentMethod, removeStorePaymentMethod } = paymentMethodsSlice.actions;
export const { setLoggedUser, clearLoggedUser } = loggedUserSlice.actions;

export default configureStore({
    reducer: {
        weddings: weddingsSlice.reducer,
        contacts: contactsSlice.reducer,
        rituals: ritualsSlice.reducer,
        ritualTypes: ritualTypesSlice.reducer,
        payments: paymentsSlice.reducer,
        paymentMethods: paymentMethodsSlice.reducer,
        providers: providersSlice.reducer,
        providerTypes: providerTypesSlice.reducer,
        meetings: meetingsSlice.reducer,
        milestones: milestonesSlice.reducer,
        loggedUser: loggedUserSlice.reducer
    }
})
