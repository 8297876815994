import React, {useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'

// AOS
import '../../../node_modules/aos/dist/aos'
import '../../../node_modules/aos/dist/aos.css'

import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

import {getMilestonesForThisWeek, getMeetingsForThisWeek, getWeddingsForThisWeek, getPaymentsForThisWeek, getContactsToCall} from "../../requests/api";

// install Swiper modules
SwiperCore.use([Navigation]);

const Index = (props) => {

    const [nbMilestones, setNbMilestones] = useState(0);
    const [nbMeetings, setNbMeetings] = useState(0);
    const [nbWeddings, setNbWeddings] = useState(0);
    const [nbPayments, setNbPayments] = useState(0);
    const [nbContacts, setNbContacts] = useState(0);

    const [milestonesDidLoad, setMilestonesDidLoad] = useState(false);
    const [meetingsDidLoad, setMeetingsDidLoad] = useState(false)
    const [weddingsDidLoad, setWeddingsDidLoad] = useState(false)
    const [paymentsDidLoad, setPaymentsDidLoad] = useState(false);
    const [contactsDidLoad, setContactsDidLoad] = useState(false)

    useEffect(() => {

        if (milestonesDidLoad === false) {
            setMilestonesDidLoad(true);
            getMilestonesForThisWeek()
                .then(function (data) {
                    if (data !== null) {
                        setNbMilestones(data.length);
                    }
                });
        }

        if (meetingsDidLoad === false) {
            setMeetingsDidLoad(true);
            getMeetingsForThisWeek()
                .then(function (data) {
                    if (data !== null) {
                        setNbMeetings(data.length);
                    }
                });
        }

        if (weddingsDidLoad === false) {
            setWeddingsDidLoad(true);
            getWeddingsForThisWeek()
                .then(function (data) {
                    if (data !== null) {
                        setNbWeddings(data.length);
                    }
                });
        }

        if (paymentsDidLoad === false) {
            setPaymentsDidLoad(true);
            getPaymentsForThisWeek()
                .then(function (data) {
                    if (data !== null) {
                        setNbPayments(data.length);
                    }
                });
        }

        if (contactsDidLoad === false) {
            setContactsDidLoad(true);
            getContactsToCall()
                .then(function (data) {
                    if (data !== null) {
                        setNbContacts(data.length);
                    }
                });
        }


    }, [nbMilestones, nbMeetings, nbWeddings, nbPayments, nbContacts, milestonesDidLoad, meetingsDidLoad, weddingsDidLoad, paymentsDidLoad, contactsDidLoad])

        return (
            <>
                <Row className="mt-4">
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                    <div>
                                        <h4 className="counter">{nbMilestones}</h4>
                                    </div>
                                    <div className="progress-detail">
                                        <span style={{color:'black'}} className="mb-2">Echéances cette semaine</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                    <div>
                                        <h4 className="counter">{nbMeetings}</h4>
                                    </div>
                                    <div className="progress-detail">
                                        <span style={{color:'black'}} className="mb-2">Rendez-vous cette semaine</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                   <div>
                                       <h4 className="counter">{nbWeddings}</h4>
                                   </div>
                                    <div className="progress-detail">
                                        <span style={{color:'black'}} className="mb-2">Mariages cette semaine</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                    <div>
                                        <h4 className="counter">{nbPayments}</h4>
                                    </div>
                                    <div className="progress-detail">
                                        <span style={{color:'black'}} className="mb-2">Paiements à recevoir cette semaine</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="row-cols-2">
                    <Col>
                        <div className="card">
                            <div className="card-body">
                                <div className="progress-widget">
                                    <div>
                                        <h4 className="counter">{nbContacts}</h4>
                                    </div>
                                    <div className="progress-detail">
                                        <span style={{color:'black'}} className="mb-2">Personnes à contacter</span>
                                    </div>
                                    <div>
                                        <ul>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="card">
                            <Link to="/weddings/new" style={{height:'100%'}} className="btn btn-primary -pull-right">Ajouter un mariage</Link>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

export default Index
