import React, {useEffect, useState} from 'react'
import {Row, Col, Badge} from 'react-bootstrap'
import Card from '../../components/Card'
import { Link } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import {useDispatch, useSelector} from "react-redux";
import '@fortawesome/fontawesome-free/css/all.css';
import {
    getMilestonesRequest
} from "../../requests/api";
import {
    setStoreMilestones
} from "../../store/redux";
import WeddingTitle from "../../components/WeddingTitle";

const statusOptions = {
    1: 'A valider',
    2: 'Confirmé',
    3: 'Terminé',
    4: 'Annulé'
};


const columns = [{
    dataField: 'dueDate',
    text: 'Date',
    sort: true,
    formatter: dateConverter,
    filter: dateFilter({
        withoutEmptyComparatorOption: true,
        comparators: ["="]
    })
},{
    dataField: 'title',
    text: 'Titre',
    sort: false,
    formatter: linkFormatter,
    filter: textFilter()
},{
    dataField: 'wedding',
    text: 'Mariage',
    sort: true,
    formatter: weddingTitleFormater,
    filter: textFilter()
},{
    dataField: 'status',
    text: 'Statut',
    sort: true,
    formatter: formatStatus,
    filter: selectFilter({
        options: statusOptions
    })
},{
    dataField: 'note',
    text: 'Commentaire',
    sort: false,
    filter: textFilter()
}];

const defaultSorted = [{
    dataField: 'dueDate',
    wedding: 'asc'
}];

function formatStatus(cell) {
    let bg = 'light';

    switch(cell) {
        case 1:
            bg = "danger";
            break;
        case 2:
            bg = "warning";
            break;
        case 3:
            bg = "success";
            break;
        default:
            bg = "dark";
    }

    return (
        <h5><Badge pill bg={bg}>{statusOptions[cell]}</Badge></h5>
    )
}

function getIdFromObj(objString) {
    var n = objString.lastIndexOf('/');
    return objString.substring(n + 1);
}

function linkFormatter(cell, row) {
    return (
        <Link to={'/weddings/' + getIdFromObj(row['wedding']) + '/milestones/' + row.id + '/edit'}>{cell}</Link>
    );
}

function weddingTitleFormater(cell,row) {
    return (
        <WeddingTitle
            weddingUri={row['wedding']}
        />
    )
}


function dateConverter(dateString, context) {

    if(dateString) {

        // Fixe pour enlever la convertion UTC
        dateString = dateString.substr(0, 19);

        if (dateString === null || dateString === undefined) {
            return;
        }

        var dateToDisplay =  new Date(dateString).toLocaleDateString('fr-FR');

        return dateToDisplay;

    }

    return false;
}


const rowStyle = (row, rowIndex) => {
    const style = {};
    // TODO couleurs
    return style;
}

const getMilestones = async () => {

    const data = getMilestonesRequest();
    return data;

}
const RemoteDataTable = props => (
    <div>
        <BootstrapTable
            keyField='id'
            data={ props.data }
            columns={ columns }
            hover
            condensed
            bweddinged={ false }
            defaultSorted={ defaultSorted }
            filter={ filterFactory() }
            filterPosition="top"
            rowStyle={ rowStyle }
            //pagination={ paginationFactory() }
            noDataIndication="Aucune données ou problème de chargement des données"
        />
    </div>
);

const MilestoneList = () => {

    const dispatch = useDispatch();
    const milestones = useSelector((state) => state.milestones.milestones);
    const [milestonesDidLoad, setMilestonesDidLoad] = useState(false);

    useEffect(() => {

        if(milestonesDidLoad === false) {
            getMilestones(milestones)
                .then(function (data) {
                    setMilestonesDidLoad(true);
                    if (data !== null) {
                        dispatch(setStoreMilestones(data.map((milestone) => ({...milestone, id: milestone.id}))));
                    }
                });
        }

    }, [milestones, dispatch, milestonesDidLoad])

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <h4 className="card-title">Echéances</h4>
                        </Card.Header>
                        <Card.Body>
                            <div className="table-responsive">
                                <RemoteDataTable
                                    data={milestones}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default MilestoneList
